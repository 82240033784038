<template>
    <b-container fluid>
        <b-carousel
        id="homepage-carousel"
        v-model="slide"
        :interval="4000"
        img-width="1920"
        img-height="400"
        controls
        indicators
        background="#f1ece2"
        style="text-shadow: 1px 1px 2px #333;"
        >
            <b-carousel-slide
                v-for="slide in slides" 
                v-bind:key="slide.id"
                :caption="slide.name"
                :text="slide.caption"
                v-bind:style="{ 'background-image': 'url(' + slide.dataUrl + ')' }">
                <b-button class="mb-2" variant="btn btn-primary" :href="slide.link">Tovább a részletekre</b-button>   
            </b-carousel-slide>         
        </b-carousel>

        <h2 class="text-center" style="margin-top: 60px;">Kiemelt kategóriák</h2>
        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <b-container>
            <b-row>
                <ProductCategoryCard :category="product"
                    v-for="product in products"
                    v-bind:key="product.id"
                    column="4"/>
            </b-row>        
        </b-container>

        <div class="text-center mt-4">
            <b-button variant="outline-primary" to="/termek">Összes kategória</b-button>        
        </div>        

        <h2 class="text-center" style="margin-top: 60px;">Legutóbbi bejegyzések</h2>
        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <b-container>
            <b-row>
                <ArticleCard :publication="publication"
                    v-for="publication in publications"
                    v-bind:key="publication.id"/>
            </b-row>        
        </b-container>        

        <div class="text-center mt-4">
            <b-button variant="outline-primary" to="/blog">Összes bejegyzés</b-button>        
        </div>

        <b-row class="about">
            <b-col md="6" class="about-content">
                <h1>Fadepo Fatelep</h1>
                <editor-js-block v-for="(block, index) in sections[0]"
                    :key="index"
                    :block="block">
                </editor-js-block>
            </b-col>

            <b-col md="6" class="about-image" v-bind:style="{ 'background-image': 'url(' + firstImage + ')' }">
            </b-col>
        </b-row>          
    </b-container>
</template>

<script>
import ArticleCard from '../components/ArticleCard'
import ProductCategoryCard from '../components/ProductCategoryCard'
import EditorJsBlock from '../components/EditorJsBlock'

export default {
    data() {
        return {
            publications: [],
            products: [],
            slide: 0,
            sliding: null,
            slides: [],
            content: null,
        }
    },

    components: {
        'ArticleCard': ArticleCard,
        'ProductCategoryCard': ProductCategoryCard,
        EditorJsBlock
    },

    methods: {
        fetchSlides(){
            this.$http.get(`/publications/page/slider`, {
                params: {
                    with: 'images',
                }
            }).then(response => {
                this.slides = response.data.relations.images
            })
        },                    
    },

    computed: {
        sections(){
            if(! this.content){
                return []
            }

            var sections = []
            var currentSection = 0
            for (const i in this.content) {
                if(! sections[currentSection]){
                    sections.push([])
                }

                var block = this.content[i]

                if(block.type=='delimiter'){
                    currentSection++
                    continue
                }

                if(block.type=='header'){
                    block.data.class='title'
                }
                sections[currentSection].push(block)
            }

            return sections
        },

        firstImage(){
            if(this.page.relations.images.length == 0){
                return '/images/fadepofatelep-logo.svg'
            }

            let highlighted = this.page.relations.images.filter(o => {
                return o.highlight
            })

            if(highlighted.length > 0){
                return highlighted[0].dataUrl
            }

            return this.page.relations.images[0].dataUrl
        }           
    },

    created(){
        this.$http.get(`/publications/page/fooldal`, {
            params: {
                with: 'images'
            }
        }).then(results => {
                this.content = results.data.content.map(o => o.content)
                this.page = results.data
            })

        this.$http.get(`/publications/post?sort=['published_at', 'desc']`, {
            params: {
                with: 'category,tags,author,firstImage'
            }
        }).then(results => {
            this.publications = results.data.data.slice(0, 3)
        }) 

        this.$http.get(`/publications/product?sort=['published_at', 'desc']`, {
            params: {
                with: 'attributes,category,content,currentPrice,images,prices'
            }
        }).then(results => {
            this.products = results.data.data.slice(0, 3)
        }) 

        this.fetchSlides()
    },          
}
</script>

<style scoped>
.about-content {
    padding: 60px;
}

.about-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 250px;
}

.about {
    background: #f1ece2 !important;
    box-shadow: 4px 4px 15px rgb(0 0 0 / 15%);
    margin-top: 60px;
}

#homepage-carousel .carousel-item {
    min-height: 400px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

#homepage-carousel .btn-primary {
    text-shadow: none !important;
}
</style>