<template>
    <b-container class="my-4">     
        <h1 class="text-center">{{ title }}</h1>
        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <b-container>
            <b-row>
                <ArticleCard :publication="publication"
                    v-for="publication in publications"
                    v-bind:key="publication.id"/>
            </b-row>           

            <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                @change="handlePageChange"
                class="mt-4"
            ></b-pagination>
        </b-container>         
    </b-container>    
</template>

<script>
import ArticleCard from '../components/ArticleCard'

export default {
    data() {
        return {
            publications: [],
            page: 1,
            count: 0,
            pageSize: 9,
            title: 'Blog',
        }
    },

    components: {
        'ArticleCard': ArticleCard,
    },  

    created(){
        this.$http.get(`/publications/page/blog`)
            .then(results => {
                this.title = results.data.title
            }),       
        this.handlePageChange(1)
    },

    methods: {
        handlePageChange(value) {
            this.page = value;

            this.$http.get(`/publications/post?sort=['published_at', 'desc']`, {
                params: {
                    with: 'category,tags,author,content,firstImage',
                    page: this.page,
                    size: this.pageSize
                }
            }).then(results => {
                this.publications = results.data.data
                this.loaded = true
                this.count = results.data.meta.total
            })
        },
    },              
}
</script>