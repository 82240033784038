import Article from './views/Article.vue';
import Blog from './views/Blog.vue';
import Calculator from './views/Calculator.vue';
import Cart from './views/Cart.vue';
import Contact from './views/Contact.vue';
import Homepage from './views/Homepage.vue';
import PriceList from './views/PriceList.vue';
import Product from './views/Product.vue';
import ProductCategory from './views/ProductCategory.vue';
import Products from './views/Products.vue';
import OnSale from './views/OnSale.vue';
import Search from './views/Search.vue';
import Tag from './views/Tag.vue';
import Page from './views/Page.vue';

export default [
    {
        path: '/blog',
        name: 'blog', 
        component: Blog
    },
    {
        path: '/fureszaru-kalkulator',
        name: 'calculator', 
        component: Calculator
    },
    {
        path: '/kosar',
        name: 'cart', 
        component: Cart
    },
    {
        path: '/kapcsolat',
        name: 'contact', 
        component: Contact
    },
    {
        path: '/',
        name: 'homepage', 
        component: Homepage
    },
    {
        path: '/arlista',
        name: 'pricelist', 
        component: PriceList
    },
    {
        path: '/termek/:product',
        name: 'product', 
        component: Product
    },
    {
        path: '/termek-kategoria/:category',
        name: 'productcategory', 
        component: ProductCategory
    },
    {
        path: '/termekek',
        name: 'products', 
        component: Products
    },
    {
        path: '/akciok',
        name: 'onsale', 
        component: OnSale
    },
    {
        path: '/:page',
        name: 'page', 
        component: Page
    },      
    {
        path: '/:publication',
        name: 'article', 
        component: Article
    },     
    {
        path: '/:search',
        name: 'search', 
        component: Search
    },
    {
        path: '/tag/:tag',
        name: 'tag', 
        component: Tag
    },   
]