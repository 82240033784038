<template>
  <div id="app">
    <Navbar/>

    <router-view></router-view>

    <Footer/>
  </div>
</template>

<script>
import Navbar from './components/Navbar';
import Footer from './components/Footer';

export default {
  components: {
    'Navbar': Navbar,
    'Footer': Footer,
  }
}
</script>

<style>
#app, body {
  font-family: 'Open Sans',arial,sans-serif;
  color: #332013;
  background-color: #a98467;
}

h1 {
  font-weight: 700 !important;
  text-transform: uppercase;
}

h2 {
  font-weight: 700 !important;
  text-transform: uppercase;
}

h3 {
  font-weight: 700 !important;
  text-transform: uppercase;
}

h4 {
  font-weight: 700 !important;
  text-transform: uppercase;
}

h5 {
  font-weight: 700 !important;
  text-transform: uppercase;
}

h6 {
  font-weight: 700 !important;
  text-transform: uppercase;
}

a {
  color: #9eb357 !important;
}

a:hover {
  color: #84954a;
  text-decoration: underline;
}

.btn-primary {
  background: #9eb357 !important;
  font-weight: 700 !important;
  padding: 0.75rem 1.5rem !important;
  border: none !important;
  border-radius: 10px !important;
  line-height: 1 !important;
  color: #f1ece2 !important;
  text-transform: uppercase;
}

.btn-primary:hover, .btn-primary:active {
  background: #84954a !important;
}

.btn-outline-primary {
  border: 2px solid #9eb357 !important;
  color: #f1ece2 !important;
  font-weight: 700 !important;
  padding: 0.75rem 1.5rem !important;
  border-radius: 10px !important;
  line-height: 1 !important;
  text-transform: uppercase;
}

.btn-outline-primary:hover, .btn-outline-primary:active {
  background: #9eb357 !important;
  color: #f1ece2 !important;
}

.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.divider {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
}

.divider-line {
    width: 50px;
    margin: 0 auto;
    border-top: 5px solid #9eb357;
    display: flex;
    margin-bottom: .5rem;
}

.paginate-links li.page-item {
    cursor: pointer;
}

.page-item.active .page-link {
    color: #f1ece2 !important;
    border: none !important;
    background-color: #9eb357 !important;
}

.page-item.active .page-link:hover {
    color: #f1ece2 !important;
    background-color: #84954a !important;
}

.page-link {
    color: #332013 !important;
    border: none !important;
    background-color: #f1ece2 !important;
}

.page-link:hover {
    color: #f1ece2 !important;
    border: none !important;
    background-color: #9eb357 !important;
}

.page-item.disabled .page-link {
    color: #332013 !important;
    background-color: #f1ece2 !important;
    border: none !important;
    opacity: 0.9 !important;
}

.page-item {
    background-color: #f1ece2 !important;
}

.disabled.page-item {
    cursor: default !important;
}

.jumbotron-fluid {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #4040409e !important;
    background-blend-mode: overlay;
    color: #f1ece2;
    text-align: center;
}

.jumbotron-fluid a {
    color: #9eb357 !important;
}

@media screen and (max-width: 576px){
    .article-meta {
        display: block;
        margin: 10px 0px;
    }
}

.article-meta .b-icon {
    color: #9eb357;
}

.jumbotron-fluid .display-3 {
    font-size: 2.5rem !important;
    font-weight: 700 !important;
}

.card {
    background: #f1ece2 !important;
    box-shadow: 4px 4px 15px rgb(0 0 0 / 15%);
    border-radius: 10px !important;
    border: none !important;
}

.card-footer {
    border: none !important;
    background-color: transparent !important;
    align-self: self-end;
    width: 100% !important;
}

.card-footer .btn-primary {
  width: 100% !important;
}
</style>
