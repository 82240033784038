<template>
    <b-container class="my-4">
        <h1 class="text-center">{{ title }}</h1>
        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <table class="table table-striped">
        <thead>
            <tr>
            <th scope="col">#</th>
            <th scope="col">Termék</th>
            <th scope="col">Ár</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <th scope="row">1</th>
            <td><router-link :to="'/termek/'+productSlug">{{ productTitle }}</router-link></td>
            <td>{{ productPrice }} Ft</td>
            </tr>
        </tbody>
        </table>

        <form 
            class="my-4"
            method="post"
            action="http://localhost:8000/api/orders"
        >
            <b-form-input v-model="form.name" 
                :type="text"
                placeholder="Név">
            </b-form-input>
            
            <b-form-input v-model="form.email"
                :type="email"
                id="email" 
                placeholder="E-mail">
            </b-form-input>

            <b-form-input v-model="form.phone"
                :type="text"
                id="phone" 
                placeholder="Telefonszám">
            </b-form-input>

            <b-form-input v-model="form.address" 
                :type="text"
                placeholder="Cím">
            </b-form-input>            
            
            <b-form-textarea id="textarea"
                v-model="form.note"
                placeholder="Üzenet"
                rows="3"
                max-rows="6">
            </b-form-textarea>

            <b-button type="submit" variant="primary" class="btn btn-secondary">Küldés</b-button>
        </form>     
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            title: 'Kosár',
            form: {
                name: '',
                email: '',
                phone: '',
                address: '',
                note: '',
            },              
        }
    },

    created() {
        this.productTitle = localStorage.getItem('title')
        this.productPrice = localStorage.getItem('price')
        this.productSlug = localStorage.getItem('slug')
    }    
}
</script>

<style scoped>
.form-control {
    color: #332013 !important;
    background-color: #f1ece2 !important;
    border: 2px solid #9eb357 !important;
    margin-bottom: 1rem;
}
</style>