<template>
    <b-container class="my-4 product" v-if="product">
        <b-row>
            <b-col class="text-center mb-2" md="6" order-md="2">
                <h1 class="product-title">{{ product.title }}</h1>
                <div class="divider">
                    <span class="divider-line"></span>
                </div>

                <p class="price">Bruttó: {{ product.relations.currentPrice.price }} Ft</p>

                <b-form-select class="variation-select" v-if="product.product_type === 2" v-model="selected" :options="product.relations.variations" value-field="title" text-field="title"></b-form-select>

                <b-form-input id="quantity" type="number" v-if="product.product_type === 0 || product.product_type === 2" v-model="quantity" min="1" max="50" step="1"></b-form-input>

                <b-button class="my-4" variant="primary" @click="addToCart">Ajánlatkérés</b-button>  
            </b-col>

            <b-col class="mb-2" md="6" order-md="1">
                <img :src="firstImage" 
                    class="img-fluid mb-4 featured-image">
                                   
                <b-row>
                    <b-col cols="6" md="4" v-for="(image, index) in product.relations.images" v-bind:key="index">
                        <img :src="image.dataUrl" 
                            class="img-fluid mb-4 gallery-image">
                    </b-col>
                </b-row>
            </b-col>            
        </b-row>

        <b-card no-body>
            <b-tabs card>
                <b-tab title="Leírás" active>
                    <editor-js-block v-for="(block, index) in product.relations.content"
                        v-bind:key="index"
                        :block="block.content">
                    </editor-js-block>
                </b-tab>

                <b-tab title="Tulajdonságok">
                    <table class="table table-striped table-borderless table-hover table-sm">
                        <tbody>
                            <tr v-for="attribute in product.relations.attributes"
                                :key="attribute.name">
                                <th scope="row">{{ attribute.name }}</th>
                                <td>{{ attribute.value }} {{ attribute.unit }}</td>
                            </tr>
                        </tbody>
                    </table>   
                </b-tab>
            </b-tabs>
        </b-card>

        <h2 class="text-center mt-4">További termékek</h2>
        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <b-row>
            <ProductCard :product="related"
                v-for="related in product.relations.related.slice(0, 3)"
                v-bind:key="related.slug"
                column="4"/>
        </b-row>           
    </b-container>
</template>

<script>
import EditorJsBlock from '../components/EditorJsBlock'
import ProductCard from '../components/ProductCard'

export default {
    data() {
        return {  
            product: null,
            products: [],
            selected: null,
            variation: [],
            quantity: '1'
        }
    },

    components: {
        EditorJsBlock,
        'ProductCard': ProductCard,
    },

    computed:{
        firstImage(){
            if(this.product.relations.images.length == 0){
                return '/images/fadepofatelep-logo.svg'
            }

            let highlighted = this.product.relations.images.filter(o => {
                return o.highlight
            })

            if(highlighted.length > 0){
                return highlighted[0].dataUrl
            }

            return this.product.relations.images[0].dataUrl
        }        
    },

    watch: {
        title(newTitle) {
            localStorage.title = newTitle;
        },

        price(newPrice) {
            localStorage.price = newPrice;
        },

        slug(newSlug) {
            localStorage.slug = newSlug;
        },

        quantity(newQuantity) {
            localStorage.quantity = newQuantity;
        }
    },

    methods: {
        fetchProduct(){
            this.$http.get(`/publications/product/${this.$route.params.product}`, {
                params: {
                    with: 'attributes,category,content,currentPrice,images,prices,related,variations',
                }
            }).then(response => {
                this.product = response.data.data
            })
        },

        addToCart() {
            localStorage.title = this.product.title;
            localStorage.price = this.product.relations.currentPrice.price;
            localStorage.slug = this.product.slug;
            localStorage.quantity = this.quantity;
            localStorage.selected = this.selected;
        }                            
    },

    mounted() {
        if (localStorage.title) {
            this.product.title = localStorage.product;
        }

        if (localStorage.price) {
            this.product.relations.currentPrice.price = localStorage.price;
        }

        if (localStorage.slug) {
            this.product.slug = localStorage.slug;
        }

        if (localStorage.quantity) {
            this.quantity = localStorage.quantity;
        }

        if (localStorage.variation) {
            this.selected = localStorage.variation;
        }           
    },

    created(){
        this.fetchProduct()
    }                        
}
</script>

<style>
th {
    font-weight: 700;
    width: 30%;
    padding: 5px 20px !important;
}

td {
    width: 70%;
    padding: 5px 20px !important;
}

.card-header {
    background-color: #f1ece2 !important;
    border-bottom: 1px solid #9eb357 !important;
}

.card-header:first-child {
    border-radius: 10px 10px 0 0 !important;
}

.nav-tabs .nav-link.active {
    background-color: #f1ece2 !important;
    border-color: #9eb357 #9eb357 #f1ece2 !important;
}

.nav-tabs .nav-link:hover {
    border-color: #f1ece2 #f1ece2 #84954a !important;
    color: #84954a !important;
}

.nav-tabs .nav-link.active:hover {
    border-color: #84954a #84954a #f1ece2 !important;
    color: #84954a !important;
}

p.price {
    color: #9eb357;
    font-weight: 700;
    font-size: 1.5rem;
}

.featured-image {
    border-radius: 10px;
    box-shadow: 4px 4px 15px rgb(0 0 0 / 15%);
}

.gallery-image {
    border-radius: 10px;
}

.product .variation-select {
    color: #332013 !important;
    background-color: #f1ece2 !important;
    border: 2px solid #9eb357 !important;
    width: 250px;
    display: block;
    margin: 0 auto;
}

.product #quantity {
    width: 60px;
    margin-right: 10px;
    display: inline;
    background-color: #f1ece2 !important;
    border: 2px solid #9eb357 !important;
    color: #332013 !important;
}
</style>