import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Routes from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueGtag from "vue-gtag"

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(VueGtag, {
  config: { id: "" }
},router);

axios.interceptors.request.use(function (config) {
  var AUTH_TOKEN = ''

  if (process.env.NODE_ENV == 'production') {
    AUTH_TOKEN = '398RiymbKEkmHulJAClgGdkP6u8Nx9NkDMv52E9neVIjEE3RrXNgewBOdhGddsOTAihb5U2rlTkT6VjBoyAKBzwJz9'
    config.baseURL = 'https://dev.fadepo.hu/api'
  } else {
    AUTH_TOKEN = '123456789'
    config.baseURL = 'http://localhost:8000/api'
  }
  
  if (AUTH_TOKEN) {
    config.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`
  }

  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

const router = new VueRouter({
  routes: Routes,
  mode: 'history'
})

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')