<template>
    <b-container fluid class="mb-4">
        <b-jumbotron fluid v-bind:style="{ 'background-image': 'url(' + imageUrl + ')' }">
            <template #header header-level="2">
                {{ publication.title }}
                <div class="divider">
                    <span class="divider-line"></span>
                </div>                        
            </template>

            <template #lead>
                <span class="mx-2 article-meta">
                    <b-icon icon="clock-fill" aria-hidden="true" class="mr-2"></b-icon>Publikálva: {{ publication.published_at }}
                </span>
                <span class="mx-2 article-meta" v-if="!!publication.relations.category">
                    <b-icon icon="tag-fill" aria-hidden="true" class="mr-2"></b-icon>Címke: <span v-for="tag in publication.relations.tags" v-bind:key="tag.id"><router-link :to="'tag/' + tag.slug">{{ tag.name }}</router-link></span>
                </span>
            </template>
        </b-jumbotron>

        <b-container>
            <editor-js-block v-for="(block, index) in sections"
                v-bind:key="index"
                :block="block.content">
            </editor-js-block>

            <h2 class="text-center mt-5">Ajánlott termékek</h2>
            <div class="divider">
                <span class="divider-line"></span>
            </div>

            <b-row>
                <ProductCard :product="product"
                    v-for="product in products"
                    v-bind:key="product.id"
                    column="4"/>
            </b-row>                  
        </b-container>            
    </b-container>    
</template>

<script>
import EditorJsBlock from '../components/EditorJsBlock'
import ProductCard from '../components/ProductCard'

export default {
    data() {
        return {  
            publication: null,
            products: [],
        }
    },

    components: {
        EditorJsBlock,
        'ProductCard': ProductCard,
    },

    computed: {
        url(){
            return `/${this.publication.slug}`
        },
        imageUrl(){
            let url = this.publication?.content[0]?.content?.data?.url

            return url ? url : '/images/default-featured-image.jpg'
        },
        sections(){
            var hasImage = false

            return this.publication.content.filter(item => { 
                if (item.content.type != 'image') { 
                    return true
                } else { 
                    if (hasImage){ 
                        return true
                    } else { 
                        hasImage = true

                        return false
                    }
                } 
            })
        },
    },

    methods: {
        fetchPublication(){
            this.$http.get(`/publications/post/${this.$route.params.publication}`, {
                params: {
                    with: 'category,tags,author,content',
                }
            }).then(response => {
                this.publication = response.data
                this.description = response.data.description
                this.title = response.data.title
            }).catch(error => {
                if (error.response.status === 404) {
                    window.location.href = '/404';
                } else {
                    console.log(error)
                }                  
            })
        },

        fetchProducts() {
            this.$http.get(`/publications/product?sort=['published_at', 'desc']`, {
                params: {
                    with: 'attributes,category,content,currentPrice,images,prices'
                }
            }).then(results => {
                this.products = results.data.data.slice(0, 3)
                this.loaded = true
            })
        },        
    }, 

    created(){
        this.fetchPublication(),
        this.fetchProducts()
    }                        
}
</script>