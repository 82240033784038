<template>
    <b-container class="my-4" v-if="page">
        <h1 class="text-center">{{ page.title }}</h1>
        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <b-container>
            <editor-js-block v-for="(block, index) in page.content"
                v-bind:key="index"
                :block="block.content">
            </editor-js-block>               
        </b-container>            
    </b-container>    
</template>

<script>
import EditorJsBlock from '../components/EditorJsBlock'

export default {
    data() {
        return {  
            page: null,
        }
    },

    components: {
        EditorJsBlock
    },    

    methods: {
        fetchPage(){
            this.$http.get(`/publications/page/${this.$route.params.page}`, {
                params: {
                    with: 'category,images,content',
                }
            }).then(response => {
                this.page = response.data
            })
        },
       
    }, 

    created(){
        this.fetchPage()
    }                        
}
</script>