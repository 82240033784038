<template>
    <div>
        <p v-if="block.type=='paragraph'" v-html="block.data.text"></p>

        <template v-if="block.type=='header'">
            <component :is="`h${block.data.level}`" 
                :class="block.data.class ? block.data.class : ''">
                {{ block.data.text }}
            </component>
        </template>

        <blockquote v-if="block.type=='quote'" 
            class="blockquote"
            :class="{'text-center': block.data.alignment=='center'}">
            {{ block.data.text }}
            <footer class="blockquote-footer" v-if="block.data.caption!=''">
                {{ block.data.caption }}
            </footer>
        </blockquote>

        <hr v-if="block.type=='delimiter'">

        <div class="alert alert-warning text-center" v-if="block.type=='warning'">
            <strong class="font-weight-bold">{{ block.data.title }}</strong><br>
            {{ block.data.message }}
        </div>

        <template v-if="block.type=='list'">
            <component :is="block.data.style=='ordered' ? 'ol' : 'ul'">
                <li v-for="(item, index) in block.data.items" :key="index">{{ item }}</li>
            </component>
        </template>

        <template v-if="block.type=='checklist'">
            <div class="form-check" v-for="(item, index) in block.data.items" :key="index">
                <input type="checkbox" 
                    class="form-check-input" 
                    :checked="item.checked" 
                    onclick="return false">
                <label class="form-check-label">{{ item.text }}</label>
            </div>
        </template>

        <img v-if="block.type=='image'" 
            :src="block.data.url" 
            :alt="block.data.caption"
            class="img-fluid">

        <table class="table" v-if="block.type=='table'">
            <tr v-for="(row, i) in block.data.content" :key="i">
                <td v-for="(cell, j) in row" :key="j">{{ cell }}</td>
            </tr>
        </table>

        <pre v-if="block.type=='code'"><code>{{ block.data.code }}</code></pre>

        <template v-if="block.type=='embed'">
            <center>
                <iframe :width="block.data.width" 
                    :height="block.data.height" 
                    :src="block.data.embed" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
                </iframe>
            </center>
        </template>        
    </div>
</template>

<script>
export default {
    props: {
        block: { required: true, type: [Object] }
    }
}
</script>