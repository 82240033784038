<template>
    <div>
        <b-navbar type="light" variant="light" class="top-nav">
            <b-container>
                <b-navbar-nav class="ml-auto">
                    <b-nav-form>
                        <b-form-input size="sm" class="mr-sm-2" placeholder="Keresés..."></b-form-input>
                        <b-button size="sm" class="my-2 my-sm-0" type="submit"><b-icon icon="search" aria-hidden="true"></b-icon></b-button>
                    </b-nav-form>

                    <b-nav-item to="/fureszaru-kalkulator"><b-icon icon="calculator" aria-hidden="true"></b-icon></b-nav-item>
                    <b-nav-item to="/kosar"><b-icon icon="cart4" aria-hidden="true"></b-icon></b-nav-item>
                </b-navbar-nav>
            </b-container>
        </b-navbar>

        <b-navbar type="light" variant="light" class="middle-nav">
            <b-container>
                <router-link class="navbar-brand mx-auto" to="/">
                    <img src="/images/fadepofatelep-logo.svg" alt="Fadepo Fatelep">
                </router-link> 
            </b-container>
        </b-navbar>

        <b-navbar toggleable="md" type="light" variant="light" class="bottom-nav">
            <b-container>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="mx-auto">
                        <b-nav-item to="/termekek">Termékek</b-nav-item>
                        <b-nav-item to="/arlista">Fatelep árlista</b-nav-item>
                        <b-nav-item to="/blog">Blog</b-nav-item>
                        <b-nav-item to="/akciok">Akciók</b-nav-item>
                        <b-nav-item to="/kapcsolat">Kapcsolat</b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-container>
        </b-navbar>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.btn-secondary {
    background: #9eb357 !important;
    border: none !important;
    line-height: 1 !important;
    padding: 0.4rem 0.4em !important;
}

.btn-secondary:hover, .btn-secondary:active {
    background: #84954a !important;
}

.form-control {
    color: #332013 !important;
    background-color: #f1ece2 !important;
    border: 2px solid #9eb357 !important;
}

.navbar-brand img {
    max-width: 200px;
}

.bg-light {
    background-color: #6c584c !important;
}

.top-nav a.nav-link:hover {
    color: #84954a !important;
}

.top-nav a.nav-link.router-link-active {
    color: #84954a !important;
}

.top-nav .nav-link {
    line-height: 1;
}

.top-nav .nav-link svg {
    height: 30px;
    width: auto;
}


@media screen and (max-width: 768px) {
    .top-nav .navbar-nav {
        margin: 0 auto;
    }

    .top-nav .form-inline {
        flex-wrap: nowrap;
    }

    .top-nav .form-inline button {
        margin-left: 0.5rem;
    }    
}

.bottom-nav .nav-item {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    border-right: 2px solid #9eb357;
}

.bottom-nav  .nav-item:first-child > a {
    border-left: 2px solid #9eb357;
}

.bottom-nav a.nav-link.router-link-active {
    background-color: #9eb357;
    color: #f1ece2 !important;
}

@media screen and (max-width: 768px) {
    .bottom-nav  .nav-item:first-child > a {
        border-left: none;
    }
    
    .bottom-nav .nav-item {
        border-right: none;
    }  

    .bottom-nav {
        padding: 0.5rem 1rem !important;
    } 
}

.bottom-nav a.nav-link:hover {
    background-color: #9eb357;
    color: #f1ece2 !important;
}

.bottom-nav .nav-link {
    padding: 1rem 1.5rem;
}

.middle-nav {
    background-color: #a98467 !important;
}

.bottom-nav {
    padding: 0;
}
</style>