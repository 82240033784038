<template>
    <b-container class="my-4">     
        <h1 class="text-center">{{ title }}</h1>
        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <b-container>
            <b-row>
                <ProductCard :product="product"
                    v-for="product in products"
                    v-bind:key="product.id"/>
            </b-row>           

            <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                @change="handlePageChange"
                class="mt-4"
            ></b-pagination>
        </b-container>         
    </b-container>    
</template>

<script>
import ProductCard from '../components/ProductCard'

export default {
    data() {
        return {
            products: [],
            page: 1,
            count: 0,
            pageSize: 9,
            title: 'Termékek',
        }
    },

    components: {
        'ProductCard': ProductCard,
    },  

    created(){
        this.$http.get(`/publications/page/termekek`)
            .then(results => {
                this.title = results.data.title
            }),  

        this.handlePageChange(1)
    },

    methods: {
        handlePageChange(value) {
            this.page = value;

            this.$http.get(`/publications/product?sort=['published_at', 'desc']`, {
                params: {
                    with: 'attributes,category,content,currentPrice,images,prices',
                    page: this.page,
                    size: this.pageSize
                }
            }).then(results => {
                this.products = results.data.data
                this.loaded = true
                this.count = results.data.meta.total
            })
        },
    },              
}
</script>