<template>
    <footer class="page-footer">
        <b-container>
            <b-row class="text-center" align-v="center">
                <b-col md="3">
                    <a href="https://www.netmarketing.hu/" target="_blank">
                        <img src="/images/netmarketing-logo.png" alt="Online marketing reklámügynökség" class="mb-2">
                    </a>
                </b-col>
                <b-col md="6">
                    <p><b-icon class="mr-2" font-scale="1.2" icon="map" aria-hidden="true"></b-icon><a href="https://goo.gl/maps/3bZCbRtU8Hmz56HJ8" target="_blank">1182 Budapest, Üllői út 651.</a></p>
                    <p><b-icon class="mr-2" font-scale="1.2" icon="mailbox" aria-hidden="true"></b-icon><a href="mailto:info@fadepo.hu">info@fadepo.hu</a></p>
                    <p><b-icon class="mr-2" font-scale="1.2" icon="telephone" aria-hidden="true"></b-icon><a href="tel:+36-1-296-0018">+36-1-296-0018</a></p>
                    <p><b-icon class="mr-2" font-scale="1.2" icon="phone" aria-hidden="true"></b-icon><a href="tel:+36-30-622-1719">+36-30-622-1719</a></p>
                    <p><b-icon class="mr-2" font-scale="1.2" icon="clock" aria-hidden="true"></b-icon><a> H-P: 8-17 Sz: 8-13</a></p>

                </b-col>
                <b-col md="3">
                    <a href="https://itzen.hu/" target="_blank">
                        <img src="/images/itzen-logo.png" alt="Weboldal készítés" class="mb-2">
                    </a>
                </b-col>
            </b-row>
        </b-container>        
        <div class="footer-copyright text-center">
            Copyright © 2021
        </div>
    </footer>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.page-footer img {
    max-width: 120px;
}

.page-footer p {
    margin-bottom: .5rem !important;
}

.page-footer {
    background-color: #6c584c;
    padding-top: 1.5rem;
}

.footer-copyright.text-center {
    border-top: 2px solid #9eb357;
    margin-top: 1rem;
    padding: .5rem;
}
</style>