<template>
    <b-container class="my-4">
        <h1 class="text-center">{{ title }}</h1>
        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <form class="my-2">
            <b-form-input v-model="form.name" 
                :type="text"
                placeholder="Név">
            </b-form-input>
            
            <b-form-input v-model="form.email"
                :type="email"
                id="email" 
                placeholder="E-mail">
            </b-form-input>

            <b-form-input v-model="form.phone"
                :type="text"
                id="phone" 
                placeholder="Telefonszám">
            </b-form-input>            
            
            <b-form-textarea id="textarea"
                v-model="form.note"
                placeholder="Üzenet"
                rows="3"
                max-rows="6">
            </b-form-textarea>

            <b-button type="submit" variant="primary" class="btn btn-secondary">Küldés</b-button>
        </form>

        <b-row class="contact" no-gutters>
            <b-col md="6" class="contact-content">
                <p><b-icon class="mr-2" font-scale="1.2" icon="map" aria-hidden="true"></b-icon><a href="https://goo.gl/maps/3bZCbRtU8Hmz56HJ8" target="_blank">1182 Budapest, Üllői út 651.</a></p>
                <p><b-icon class="mr-2" font-scale="1.2" icon="mailbox" aria-hidden="true"></b-icon><a href="mailto:info@fadepo.hu">info@fadepo.hu</a></p>
                <p><b-icon class="mr-2" font-scale="1.2" icon="telephone" aria-hidden="true"></b-icon><a href="tel:+36-1-296-0018">+36-1-296-0018</a></p>
                <p><b-icon class="mr-2" font-scale="1.2" icon="phone" aria-hidden="true"></b-icon><a href="tel:+36-30-622-1719">+36-30-622-1719</a></p>
                <p><b-icon class="mr-2" font-scale="1.2" icon="clock" aria-hidden="true"></b-icon><a> H-P: 8-17 Sz: 8-13</a></p>               
            </b-col>

            <b-col md="6" class="contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2698.8229806655304!2d19.19849341562449!3d47.434894979173734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741c222d4fc9b8b%3A0x36222fb213adfd8a!2sFadepo%20Fatelep!5e0!3m2!1shu!2shu!4v1619685370325!5m2!1shu!2shu" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </b-col>
        </b-row>    
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            title: 'Kapcsolat',
            form: {
                name: '',
                email: '',
                phone: '',
                note: '',
            },            
        }
    },

    created(){
        this.$http.get(`/publications/page/kapcsolat`)
            .then(results => {
                this.title = results.data.title
            })
    },        
}
</script>

<style scoped>
.contact-content {
    padding: 60px !important;
}

.contact-map {
    min-height: 250px;
}

.contact {
    background: #f1ece2 !important;
    box-shadow: 4px 4px 15px rgb(0 0 0 / 15%);
    border-radius: 10px !important;
    margin-top: 40px;
}

.form-control {
    color: #332013 !important;
    background-color: #f1ece2 !important;
    border: 2px solid #9eb357 !important;
    margin-bottom: 1rem;
}
</style>