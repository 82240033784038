<template>
    <b-col :md="column">
        <b-card no-body class="my-3">
            <router-link :to="url">
                <b-card-img :src="firstImage" :alt="product.title"></b-card-img>
            </router-link>

            <b-card-body :title="product.title">
                <b-card-text>
                    
                </b-card-text>
            </b-card-body>

            <b-card-footer>
                <b-button variant="primary" :to="url">Tovább a termékre</b-button>       
            </b-card-footer>            
        </b-card>   
    </b-col>       
</template>

<script>
export default {
    props: {
        'product': {required: true, type: [Object]},
        'column': {default: 6, String, Number }
    },
    computed:{
        url(){
            return `/termek/${this.product.slug}`
        },

        firstImage(){
            if(this.product.relations.images.length == 0){
                return '/images/fadepofatelep-logo.svg'
            }

            let highlighted = this.product.relations.images.filter(o => {
                return o.highlight
            })

            if(highlighted.length > 0){
                return highlighted[0].dataUrl
            }

            return this.product.relations.images[0].dataUrl
        }        
    }
}
</script>