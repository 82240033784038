<template>
    <b-container class="my-4">
        <h1 class="text-center">{{ title }}</h1>
        <div class="divider">
            <span class="divider-line"></span>
        </div>        
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            title: 'Fűrészáru kalkulátor',
        }
    },

    created(){
        this.$http.get(`/publications/page/fureszaru-kalkulator`)
            .then(results => {
                this.title = results.data.title
            })
    },        
}
</script>

<style scoped>

</style>