<template>
    <h1>Product Category</h1>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>