<template>
    <b-container fluid class="my-4">     
        <h1 class="text-center">Címke: {{ tag.name }}</h1>
        <div class="divider">
            <span class="divider-line"></span>
        </div>

        <b-container>
            <b-row>
                <ArticleCard :publication="publication"
                    v-for="publication in tag.relations.publications"
                    v-bind:key="publication.id"/>
            </b-row>           

            <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                @change="handlePageChange"
                class="mt-4"
            ></b-pagination>
        </b-container>         
    </b-container>    
</template>

<script>
import ArticleCard from '../components/ArticleCard'

export default {
    data() {
        return {
            tag: [],
            page: 1,
            count: 0,
            pageSize: 9,
            title: '',
        }
    },

    components: {
        'ArticleCard': ArticleCard,
    },

    methods: {
        handlePageChange(value) {
            this.page = value;

            this.$http.get(`/tags/${this.$route.params.tag}?sort=['published_at', 'desc']`, {
                params: {
                    with: 'publications',
                    page: this.page,
                    size: this.pageSize
                }
            }).then(response => {
                this.tag = response.data.data
                this.loaded = true
                this.count = response.data.meta.total
            })
        },                
    },

    created(){
        this.handlePageChange(1)
    }             
}
</script>