import { render, staticRenderFns } from "./OnSale.vue?vue&type=template&id=6c362223&scoped=true&"
import script from "./OnSale.vue?vue&type=script&lang=js&"
export * from "./OnSale.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c362223",
  null
  
)

export default component.exports