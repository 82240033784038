<template>
<b-col cols="12">
    <b-card no-body class="my-3">
        <b-row no-gutters>
            <b-col md="5">
                <router-link :to="url">
                    <b-card-img :src="imageUrl" :alt="publication.title"></b-card-img>
                </router-link>
            </b-col>

            <b-col md="7" style="display: grid;">
                <b-card-body :title="publication.title">
                    <b-card-text>
                        {{ publication.description.slice(0, 130) }}...
                    </b-card-text>              
                </b-card-body>
                
                <b-card-footer>
                    <b-button variant="primary" :to="url">Tovább a bejegyzésre</b-button>   
                </b-card-footer>
            </b-col>
        </b-row>
    </b-card>   
</b-col>  
</template>

<script>

export default {
    props: {
        'publication': {required: true, type: [Object]}
    },
    computed:{
        url(){
            return `/${this.publication.slug}`
        },
        imageUrl(){
            let url = this.publication.relations.firstImage?.content?.data?.url

            return url ? url : '/images/fadepofatelep-logo.svg'
        },
        sections(){
            if(! this.page){
                return []
            }

            var sections = []
            var currentSection = 0
            for (const i in this.page) {
                if(! sections[currentSection]){
                    sections.push([])
                }

                var block = this.page[i]

                if(block.type=='delimiter'){
                    currentSection++
                    continue
                }

                if(block.type=='header'){
                    block.data.class='title'
                }
                sections[currentSection].push(block)
            }

            return sections
        }
    }
}
</script>